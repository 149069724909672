<template>
  <div class="sidebar">
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <div class="logo" v-if="!isCollapse">
        <img src="../../assets/img/logo.png" alt="" />
      </div>
      <el-menu
        :default-active="onRoutes"
        class="el-menu-vertical-demo"
        :collapse="isCollapse"
        text-color="var(--sider-text-color)"
        active-text-color="var(--sider-text-active-color)"
        background-color="var(--sider-bg-color)"
        @select="select"
        :collapse-transition="false"
        unique-opened
      >
        <template v-for="item in items">
          <template v-if="item.subs && item.subs.length !== 0">
            <el-submenu :index="item.id.toString()" :key="item.id">
              <template slot="title">
                <svg-icon class="svg-container" :icon-class="item.icon" />
                <span slot="title" class="menu_title">{{ item.title }}</span>
              </template>
              <el-menu-item
                v-for="subItem in item.subs"
                :key="subItem.id"
                :index="subItem.index"
                popper-class="var(--sider-sub-bg-color)"
                @mouseenter.native="elementBShow($event, subItem.index)"
                @mouseleave.native="elementBHidden()"
              >
                {{ subItem.title }}
                <el-button
                  v-show="ifShowB && subItem.index == current"
                  @click.stop="toAnotherPage(subItem.index)"
                  class="caretTop"
                  type="info"
                  size="mini"
                  icon="el-icon-caret-right"
                  circle
                />
              </el-menu-item>
            </el-submenu>
          </template>
          <template v-else>
            <el-menu-item
              :index="item.index"
              :key="item.id"
              @mouseenter.native="elementBShow($event, item.index)"
              @mouseleave.native="elementBHidden()"
            >
              <svg-icon class="svg-container" :icon-class="item.icon" />
              <span slot="title" class="menu_title">
                {{ item.title }}
                <el-button
                  v-show="ifShowB && item.index == current"
                  @click.stop="toAnotherPage(item.index)"
                  class="caretTop"
                  type="info"
                  size="mini"
                  icon="el-icon-caret-right"
                  circle
                />
              </span>
            </el-menu-item>
          </template>
        </template>
      </el-menu>
    </el-scrollbar>
  </div>
</template>
<script>
  import { mapState } from 'vuex';
  export default {
    data() {
      return {
        menu: [],
        ifShowB: false,
        current: null,
      };
    },
    async mounted() {
      try {
        const result = await this.$store.dispatch('app/getMenuList');
        this.menu = result;
        this.select(this.$route.path, this.$route.fullPath);
      } catch (error) {
        this.$message.error(error);
        console.log(error);
      }
      // let param = {};
      // securityApi.securityService.getMenu(param, (response) => {
      //   if (response.status.code == 0) {
      //     this.menu = response.data.data;
      //     this.$store.commit('SET_MENULIST', this.menu);
      //   } else {
      //     this.$message.error(response.status.msg);
      //   }
      // });
    },
    computed: {
      onRoutes() {
        return this.$route.path;
      },
      ...mapState({
        isCollapse: (state) => state.app.isCollapse,
      }),
      items() {
        const roles = this.$store.state.user.role;
        return this.filterMenus(this.menu, roles);
      },
    },
    methods: {
      /**
       * 通过meta.role判断是否与当前用户权限匹配
       * @param roles
       * @param menu
       */
      hasPermission(roles, menu) {
        if (menu.meta && menu.meta.roles) {
          return roles.some((role) => menu.meta.roles.includes(role));
        } else {
          return true;
        }
      },
      /**
       * @param {Arrary} menus 菜单
       * @param {Arrary} roles 角色
       * @return {Arrary} res 过滤后的菜单
       */
      filterMenus(menus, roles) {
        const res = [];
        menus.forEach((route) => {
          const tmp = { ...route };
          if (this.hasPermission(roles, tmp)) {
            if (tmp.subs) {
              tmp.subs = this.filterMenus(tmp.subs, roles);
            }
            res.push(tmp);
          }
        });
        return res;
      },
      elementBShow(e, index) {
        this.ifShowB = true;
        this.current = index;
      },
      elementBHidden() {
        this.ifShowB = false;
        this.current = null;
      },
      select(index, indexPath) {
        let title;
        console.log('+++++++++++++');
        console.log(index);
        console.log(indexPath);
        console.log(this.items);
        console.log('+++++++++++++');
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].index == index) {
            title = this.items[i].title;
          }
          if (this.items[i].subs !== undefined) {
            for (var j = 0; j < this.items[i].subs.length; j++) {
              if (this.items[i].subs[j].index == index) {
                title = this.items[i].subs[j].title;
              }
            }
          }
        }
        if (index !== null) {
          let breadList = [];
          breadList.push({ ...indexPath, title: title });
          this.$store.commit('app/SET_BREAD_LIST', breadList);
        }
        if (this.$route.path !== index) {
          this.$router.push({
            path: index,
          });
        }
      },
      toAnotherPage(index) {
        const { href } = this.$router.resolve({
          path: index,
        });
        window.open(href, '_blank');
      },
    },
  };
</script>
<style lang="scss" scoped>
  .sidebar {
    -webkit-transition: width 0.28s;
    transition: width 0.28s;
    height: 100%;
    position: fixed;
    font-size: 0px;
    // top: 60px;
    bottom: 0;
    left: 0;
    z-index: 1001;
    overflow: hidden;
    background: var(--sider-bg-color);
    .logo {
      padding: 20px 0;
      text-align: center;
    }
    .el-menu-vertical-demo:not(.el-menu--collapse) {
      width: 220px;
      height: 100%;
      text-align: left;
      margin-bottom: 70px;
      background: none;
    }
    .el-menu--collapse {
      height: 100%;
      width: 40px;
    }
    .svg-container {
      position: absolute;
      left: 10px;
      top: 20px;
      // margin-right:6px;
    }
    .menu_title {
      position: absolute;
      left: 40px;
      top: 0px;
      font-size: 14px;
    }
  }
</style>
<style lang="scss">
  /* 改变elementui 侧边栏移入颜色 */
  // .el-menu-item {
  //   background: #000d17;
  // }
  .el-menu-item:hover {
    background: var(--sider-hover-bg-color) !important;
    color: #fff !important;
  }
  .el-submenu__title:hover {
    background: var(--sider-hover-bg-color) !important;
    color: #fff !important;
  }
  .el-menu-item.is-active {
    background: var(--sider-hover-bg-color) !important;
    color: #fff !important;
  }
  .el-submenu__title.is-active {
    background: var(--sider-hover-bg-color) !important;
    color: #fff !important;
  }
  .el-menu-item,
  .el-submenu__title {
    font-size: 16px !important;
    padding: 0 20px;
    cursor: pointer;
    -webkit-transition: border-color 0.3s, background-color 0.3s, color 0.3s;
    transition: border-color 0.3s, background-color 0.3s, color 0.3s;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .caretTop {
    margin-left: 6px;
    padding: 2px !important;
  }
  .el-submenu .el-menu-item {
    height: auto;
    line-height: 1.4em;
    padding: 10px 20px;
    white-space: normal;
    word-break: break-word;
    background: var(--sider-sub-bg-color) !important;
  }
  .el-menu-item i {
    color: inherit;
  }
  .el-menu-item [class^='el-icon-'] {
    margin-right: 0px !important;
    width: 14px;
    text-align: center;
    font-size: 14px;
    vertical-align: middle;
  }
</style>
