<template>
  <div class="head-container clearfix" :class="isCollapse == true ? 'container_collapse' : ''">
    <el-row :gutter="10">
      <el-col :xs="18" :sm="18" :md="18" :lg="18" :xl="18">
        <div class="header-left">
          <!-- <div class="title hidden-sm-and-down">Management Console</div> -->
          <showAside :toggle-click="toggleClick" />
          <breadcrumb />
        </div>
      </el-col>

      <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
        <div class="header-right">
          <div class="header-user-con">
            <!-- 信封 -->
            <!-- <div class="btn-message hidden-sm-and-down">
              <el-badge :value="messageValue">
                <router-link to="/notify/email-list?type=dail">
                  <i class="el-icon-message"></i>
                </router-link>
              </el-badge>
            </div> -->
            <!-- 全屏显示 -->
            <div class="btn-fullscreen hidden-sm-and-down" @click="handleFullScreen">
              <el-tooltip
                effect="dark"
                :content="fullscreen ? 'cancelFullScreen' : 'fullScreen'"
                placement="bottom"
              >
                <i class="el-icon-rank"></i>
              </el-tooltip>
            </div>
            <!-- 消息中心 -->
            <div class="btn-bell hidden-sm-and-down">
              <el-badge :value="value">
                <router-link to="/notify/monitor_alerts">
                  <i class="el-icon-bell"></i>
                </router-link>
              </el-badge>
            </div>
            <!-- 用户名下拉菜单 -->
            <el-dropdown class="avatar-container" trigger="click" @command="handleCommand">
              <div class="avatar-wrapper">
                <!-- <img :src="imgUrl" class="user-avatar" /> -->
                <i class="el-icon-user-solid" />
              </div>
              <el-dropdown-menu slot="dropdown" class="user-dropdown">
                <router-link class="inlineBlock" to="/">
                  <el-dropdown-item>Back to</el-dropdown-item>
                </router-link>
                <el-dropdown-item command="a">Settings</el-dropdown-item>
                <el-dropdown-item divided>
                  <span style="display:block;" @click="logout">Logout</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- 更新密码 -->
    <el-dialog
      title="Update Password"
      width="40%"
      :visible.sync="updatePasswordVisible"
      :append-to-body="true"
      :close-on-click-modal="false"
    >
      <el-dialog width="30%" title="Tip" center :visible.sync="innerVisible" append-to-body>
        <span>Password Confirm?</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="innerVisible = false">取 消</el-button>
          <el-button type="primary" @click="confirmUpdatePasswordClick">确 定</el-button>
        </span>
      </el-dialog>
      <el-form label-position="left">
        <el-row>
          <el-col :lg="20" :md="20" :offset="1">
            <el-form-item label="New Password" label-width="120px">
              <el-input
                v-model="updatePasswordValue"
                placeholder="new password here ..."
                show-password
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :lg="20" :md="20" :offset="1">
            <el-form-item label="Old Password" label-width="120px">
              <el-input
                v-model="oldPassword"
                placeholder="old password here ..."
                show-password
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="updatePasswordVisible = false">取 消</el-button>
        <el-button type="primary" @click="innerVisible = true">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import showAside from './showAside';
  import breadcrumb from './Breadcrumb';
  import cacheImgUrl from 'static/chestnut.png';
  import { changPassword } from 'api/user';
  export default {
    components: {
      showAside,
      breadcrumb,
    },
    data() {
      return {
        fullscreen: false,
        value: '',
        imgUrl: '',
        messageValue: '',
        updatePasswordValue: '',
        oldPassword: '',
        updatePasswordVisible: false,
        innerVisible: false,
        fullWidth: document.documentElement.clientWidth,
      };
    },
    async mounted() {
      const imgUrl = sessionStorage.getItem('imgUrl');
      this.imgUrl = imgUrl !== 'undefined' ? imgUrl : cacheImgUrl;

      this.rightWidth();
    },
    computed: {
      isCollapse: {
        get: function() {
          return this.$store.state.app.isCollapse;
        },
        set: function(newValue) {
          this.$store.commit('app/SET_IS_COLLAPSE', newValue);
        },
      },
    },
    methods: {
      rightWidth() {
        if (this.fullWidth <= 768) {
          this.isCollapse = true;
        }
      },
      handleCommand(command) {
        if (command == 'a') {
          this.updatePassword();
        }
      },
      updatePassword() {
        this.updatePasswordValue = sessionStorage.getItem('passWord');
        this.updatePasswordVisible = true;
      },
      confirmUpdatePasswordClick() {
        // let param = {
        //   newPassword: this.updatePasswordValue,
        // };
        // securityApi.securityService.updatePassword(param, (response) => {
        //   if (response.status.code == 0) {
        //     this.$message({
        //       message: '修改密码成功',
        //       type: 'success',
        //     });
        //     this.searchListClick();
        //   } else {
        //     this.$message.error('添加失败' + response.status.msg);
        //   }
        // });
        // this.updatePasswordVisible = false;
        let param = {
          password: this.updatePasswordValue,
          oldPassword: this.oldPassword,
        };
        changPassword(param).then((response) => {
          // console.log('修改密码：' + param);
          if (response.code === 200) {
            this.$message({
              message: 'Update Success',
              type: 'success',
            });
            this.updatePasswordVisible = false;
            this.innerVisible = false;
            this.oldPassword = '';
            this.logout();
          } else {
            this.$message.error('Update Error:' + response.message);
            this.updatePasswordVisible = false;
            this.oldPassword = '';
            this.innerVisible = false;
          }
        });
      },
      toggleClick() {
        this.isCollapse = !this.isCollapse;
      },
      // 用户名下拉菜单选择事件
      logout() {
        this.$store.dispatch('user/logout').then(() => {
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('userName');
          this.$router.push('/login');
        });
      },
      // 全屏事件
      handleFullScreen() {
        let element = document.documentElement;
        if (this.fullscreen) {
          if (document.exitFullscreen) {
            document.exitFullscreen();
          } else if (document.webkitCancelFullScreen) {
            document.webkitCancelFullScreen();
          } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
          } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
          }
        } else {
          if (element.requestFullscreen) {
            element.requestFullscreen();
          } else if (element.webkitRequestFullScreen) {
            element.webkitRequestFullScreen();
          } else if (element.mozRequestFullScreen) {
            element.mozRequestFullScreen();
          } else if (element.msRequestFullscreen) {
            // IE11
            element.msRequestFullscreen();
          }
        }
        this.fullscreen = !this.fullscreen;
      },
    },
  };
</script>
<style lang="scss" scoped>
  $base-color: var(--header-bg-color);
  .head-container {
    height: 48px;
    position: fixed;
    right: 0;
    top: 0;
    left: 220px;
    z-index: 1002;
    line-height: 48px;
    -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 0 3px 0 rgba(0, 0, 0, 0.04);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 0 3px 0 rgba(0, 0, 0, 0.04);
    border-bottom: 1px solid #f0f0f0;
    background-color: $base-color;
  }
  .container_collapse {
    left: 0px;
  }
  .header-left {
    float: left;
    .title {
      float: left;
      font-size: 26px;
      color: #000;
      margin: 0 10px;
    }
  }
  .header-right {
    float: right;
    padding-right: 60px;
  }
  .header-user-con {
    display: flex;
    height: 50px;
    align-items: center;
  }
  .btn-fullscreen {
    transform: rotate(45deg);
    margin-right: 5px;
    font-size: 26px;
    color: #000;
  }

  .btn-message {
    position: relative;
    width: 30px;
    text-align: center;
    border-radius: 15px;
    cursor: pointer;
    font-size: 24px;
    margin-bottom: 8px;
    margin-right: 10px;
    .el-icon-message {
      color: #000;
    }
  }

  .btn-fullscreen {
    position: relative;
    width: 30px;
    // height: 30px;
    text-align: center;
    border-radius: 15px;
    cursor: pointer;
    // margin-bottom: 6px;
    // margin-left: 4px;
  }
  .btn-bell {
    position: relative;
    width: 30px;
    // height: 30px;
    text-align: center;
    border-radius: 15px;
    cursor: pointer;
    font-size: 24px;
    margin-right: 10px;
    margin-bottom: 8px;
  }
  .btn-bell-badge {
    position: absolute;
    right: 0;
    top: 8px;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background: #f56c6c;
  }
  .btn-bell .el-icon-bell {
    color: #000;
  }
  .user-name {
    margin-left: 10px;
  }
  .user-avator {
    margin-left: 20px;
  }
  .user-avator img {
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  .el-dropdown-link {
    color: #000;
    cursor: pointer;
  }
  .el-dropdown-menu__item {
    text-align: center;
  }
  .avatar-container {
    height: 50px;
    display: inline-block;
    // position: absolute;
    // right: 35px;
    .avatar-wrapper {
      cursor: pointer;
      position: relative;
      line-height: 48px;
      margin-right: 10px;
      font-size: 20px;
      .user-avatar {
        width: 58px;
        height: 50px;
        border-radius: 10px;
      }
      .el-icon-caret-bottom {
        position: absolute;
        right: -16px;
        top: 14px;
        font-size: 20px;
        color: #000;
      }
    }
  }
</style>
