import request from 'utils/request';

const api = {
  LOG_ADV: '/api/source/logAdv',
  SOURCE_LIST: '/api/source/list',
  SOURCE_LIST_ALL: '/api/source/sourceListAll',
};
/**
 * 获取广告主拉单日志
 * @param {*} query {sourceIds:Array<int>,sourceName:string,status:string,payoutType:string,fromDate:string,toDate:string,toDate:string,page:int,pageSize:int}
 */
export function getLogAdv(query) {
  return request({
    url: api.LOG_ADV,
    method: 'get',
    params: query,
  });
}
/**
 * 获取广告主列表
 *
 */
export function getSourceList() {
  return request({
    url: api.SOURCE_LIST,
    method: 'get',
  });
}

/**
 * 获取广告主列表
 *
 */
export function getSourceListALL() {
  return request({
    url: api.SOURCE_LIST_ALL,
    method: 'get',
  });
}
