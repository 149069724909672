import { getAffiliateList } from 'api/affiliate/index';
import { getAffiliateList as getAffiliateInfoList } from 'api/affiliate/overall';
const state = {
  affiliatesGroupList: [], //渠道ID
  affiliateLoading: false, //渠道下拉loading
  affiliates: [], //通过渠道取到相应pb显示 //pbTntegration
};
const mutations = {
  SET_AFFILIATE_LIST: (state, affiliatesGroupList) => {
    state.affiliatesGroupList = affiliatesGroupList;
  },
  SET_AFFILIATE_LOADING(state, affiliateLoading) {
    state.affiliateLoading = affiliateLoading;
  },
  SET_AFFILIATES(state, affiliates) {
    state.affiliates = affiliates;
  },
};
const actions = {
  async getAffiliateList({ commit }) {
    commit('SET_AFFILIATE_LOADING', true);
    const response = await getAffiliateList();
    commit('SET_AFFILIATE_LOADING', false);
    let affiliatesGroupList = [];
    if (response.code == 200) {
      let affiliatemaps = response.result;
      commit('SET_AFFILIATES', response.result);
      const CPIGorups = {
        id: 1,
        checked: false,
        label: 'CPI',
        children: [],
      };

      const CPAGorups = {
        id: 2,
        checked: false,
        label: 'CPA',
        children: [],
      };

      const CPLGroups = {
        id: 3,
        checked: false,
        label: 'CPL',
        children: [],
      };

      const PausedPending = {
        id: 4,
        checked: false,
        label: 'Pending',
        children: [],
      };

      affiliatemaps.forEach((item) => {
        if (item.status !== 'ACTIVE') {
          PausedPending.children.push({
            id: item.id,
            value: item.id,
            label: `${item.id} - ${item.company}`,
          });
        } else if (item.trafficType == 'CPA') {
          CPAGorups.children.push({
            id: item.id,
            value: item.id,
            label: `${item.id} - ${item.company}`,
          });
        } else if (item.trafficType == 'CPI') {
          CPIGorups.children.push({
            id: item.id,
            value: item.id,
            label: `${item.id} - ${item.company}`,
          });
        } else if (item.trafficType == 'CPL') {
          CPLGroups.children.push({
            id: item.id,
            value: item.id,
            label: `${item.id} - ${item.company}`,
          });
        }
      });
      affiliatesGroupList.push(CPIGorups, CPAGorups, CPLGroups, PausedPending);
    }
    commit('SET_AFFILIATE_LIST', affiliatesGroupList);
  },
  async getAffiliateInfoList({ commit }) {
    try {
      const resp = await getAffiliateInfoList();
      if (resp.code === 200) {
        commit('SET_AFFILIATES', resp.result);
      } else {
        commit('SET_AFFILIATES', []);
      }
    } catch (error) {
      commit('SET_AFFILIATES', []);
    }
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
