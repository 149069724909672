import { login, logout, select_account_roles, get_username } from 'api/user';

const state = {
  token: '',
  userName: '',
  roles: [], //用户角色
};
const getters = {
  roles: (state) => {
    return state.roles;
  },
};
const mutations = {
  //保存token
  COMMIT_TOKEN(state, object) {
    state.token = object.token;
  },
  //保存用户
  COMMIT_USERNAME(state, userName) {
    state.userName = userName;
  },
  //保存用户角色
  COMMIT_ROLE(state, roles) {
    state.roles = roles;
  },
};
const actions = {
  login({ commit }, userInfo) {
    const { username, password, platform } = userInfo;
    return new Promise((resolve, reject) => {
      const data = {
        username: username.trim(),
        password: password.trim(),
        platform,
      };
      login(data)
        .then((response) => {
          const { code, message } = response;
          if (code === 200) {
            commit('COMMIT_TOKEN', username);
            resolve(response);
          } else {
            reject(message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  logout({ commit }) {
    return new Promise((resolve, reject) => {
      logout()
        .then(() => {
          commit('COMMIT_TOKEN', '');
          commit('COMMIT_USERNAME', '');
          commit('COMMIT_ROLES', '');
          commit('app/TAGES_LIST', []);
          commit('app/SET_BREAD_LIST', ['Dashboard']);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  selectAccountRoles({ commit }) {
    return new Promise((resolve, reject) => {
      select_account_roles()
        .then((response) => {
          commit('COMMIT_ROLE', response.result);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  selectUserName({ commit }) {
    return new Promise((resolve, reject) => {
      get_username()
        .then((response) => {
          console.log('userName:' + response.result);
          commit('COMMIT_USERNAME', response.result);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
