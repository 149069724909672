import request from 'utils/request';

const api = {
  AFFILIATE_LIST: '/api/affiliate/overall/list',
  AFFILIATE_ADD: '/api/affiliate/overall/add',
  AFFILIATE_UPDATE: '/api/affiliate/overall/update',
  AFFILIATE_UPDATE_PAYOUT: '/api/affiliate/overall/updatePayOut',
};
export function getAffiliateList(query) {
  return request({
    url: api.AFFILIATE_LIST,
    method: 'get',
    params: query,
  });
}
export function insertAffiliate(data) {
  return request({
    url: api.AFFILIATE_ADD,
    method: 'post',
    data,
  });
}
export function updateAffiliate(data) {
  return request({
    url: api.AFFILIATE_UPDATE,
    method: 'put',
    data,
  });
}

export function updatePayout(data) {
  return request({
    url: api.AFFILIATE_UPDATE_PAYOUT,
    method: 'post',
    data,
  });
}
