<template>
  <div class="container bgf0">
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <div class="contents">
        <transition name="fade-transform" mode="out-in">
          <router-view></router-view>
        </transition>
      </div>
    </el-scrollbar>
  </div>
</template>
<script>
  export default {};
</script>
<style lang="scss" scoped>
  .container {
    padding-top: 48px;
    height: calc(var(--c100vh) - 0px);
    position: relative;
    width: 100%;
    overflow: hidden;
    .contents {
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }
  }
</style>
