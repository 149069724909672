<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
  // 保持100%缩放比
  // import DevicePixelRatio from '@/components/DevicePixelRatio/index';

  export default {
    data() {
      return {};
    },
    // updated() {
    //   this.$nextTick(() => {
    //     new DevicePixelRatio().init();
    //   });
    // },
  };
</script>
<style>
  #app {
    font-family: 'Gilroy', Futura, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
      Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
  }
  body .el-table th.gutter {
    display: table-cell !important;
  }
</style>
