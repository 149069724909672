<template>
  <div class="wrapper">
    <Header />
    <Sidebar class="aside-container" />
    <div class="main-container" :class="isCollapse == true ? 'container_collapse' : ''">
      <Main />
    </div>
  </div>
</template>
<script>
  import Sidebar from './Sidebar.vue';
  import Header from './Header.vue';
  import Main from './Main.vue';
  import { mapState } from 'vuex';
  import { setCssVar } from 'utils/theme';

  const HEADER_BG_COLOR_VAR = '--header-bg-color';
  const SIDER_TEXT_COLOR_VAR = '--sider-text-color';
  const SIDER_TEXT_ACTIVE_COLOR_VAR = '--sider-text-active-color';

  export default {
    components: {
      Sidebar,
      Header,
      Main,
    },
    computed: {
      ...mapState({
        isCollapse: (state) => state.app.isCollapse,
      }),
    },
    watch: {
      '$store.state.app.theme': {
        handler: function(val) {
          if (val !== 'default') {
            setCssVar(HEADER_BG_COLOR_VAR, '#337ab7');
            setCssVar(SIDER_TEXT_COLOR_VAR, '#337ab7');
            setCssVar(SIDER_TEXT_ACTIVE_COLOR_VAR, '#bdb7ff');
          }
        },
      },
    },
    mounted() {
      this.$store.dispatch('app/setTheme');
    },
  };
</script>
<style lang="scss" scoped>
  .wrapper {
    position: relative;
    height: 100%;
    width: 100%;
    .main-container {
      // min-height: 100%;
      -webkit-transition: margin-left 0.28s;
      transition: margin-left 0.28s;
      margin-left: 220px;
      position: relative;
    }
    .container_collapse {
      margin-left: 40px;
    }
  }
</style>
